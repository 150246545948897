.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.weather {
  background-color: rgba(0, 34, 85, 1);
}

.weather-info {
  border-color:rgba(0, 34, 85, 1)
}

.workplace {
  background: #282726;
}

.workplace-info{
  border-color:#282726
}

.tendie {
  background-color: #201A16;
  color: #FFE1C6;
}

.tendie-info {
  border-color: #201A16;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.overlay-container{
  position: relative;
  width: 100%;
  height: 100%;
}

.proj-image{
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  backface-visibility: hidden;
}

.radial-bg{
  background: rgb(255,240,215);
  background: radial-gradient(circle, rgba(255,240,215,1) 0%, rgba(255,221,180,1) 35%, rgba(255,220,130,1) 100%);
}

.proj-text{
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.overlay-container:hover .proj-image{
  opacity: 0.2;
}
.overlay-container:hover .proj-text{
  opacity: 1;
}
